export enum Site {
  Global = 'https://www.patronscan.com',
}

export enum Route {
  About = '/our-story',
  Advantages = '/advantages',
  AntiFraud = '/anti-fraud',
  AppDownloadLink = 'itms-services:/?action=download-manifest&url=https://secure.servalldatasystems.com/hotscan/scanner/90006/scanner.plist',
  Blog = '/id-scanning-blog-home',
  Developer = '/developer',
  DemoLink1 = 'https://calendly.com/gurdeepbrar/15min', // https://calendly.com/natalie-46/natalie?month=2022-03
  DemoLink2 = 'https://calendly.com/gurdeepbrar/15min', // 
  ExpertThanks = '/expert-thanks',
  FakeIdScanner = '/fake-id-scanner',
  GetPricing = '/get-pricing',
  Home = '/',
  Industries = '/industries',
  IdScannerBars = '/id-scanner-for-bars',
  IndustryVape = '/industry/retail-vapor',
  IndustryAutomotive = '/industry/vehicle-rental',
  IndustryProperty = '/industry/property-management',
  IndustryCannabis = '/industry/dispensaries',
  IndustryRetail = '/industry/retail',
  IndustryAntiFraud = '/anti-fraud',
  IndustryTelecom = '/industry/telecom',
  JobThanks = '/job-thanks',
  TalkToAnExpert = '/talk-to-an-expert',
  PatronscanSupportInstaller = 'https://lcdev-patronscan-support.s3.ca-central-1.amazonaws.com/DownloadAndInstallPatronscanRemoteSupport.bat',
  PricingThanks = '/pricing-thanks',
  Privacy = 'privacy',
  VaccinePassport = '/vaccine-passport',
  WorkWithUs = '/work-with-us',
}
