import ReactGA from 'react-ga';
import axios from 'axios';
import { format } from 'date-fns';
import { removeSpaces } from './remove-spaces';

interface LocationLookupRes {
  data: {
    country_code?: string;
    country_name?: string;
    city?: string;
    postal?: string;
    latitude?: number;
    longitude?: number;
    IPv4?: string;
    state?: string;
  };
}

export interface GoogleEvent {
  category: string;
  action: string;
  label: string;
}

export enum GoogleEventType {
  Url = 'url',
  Button = 'button',
  Form = 'form',
}

export interface VisitorData {
  clientID?: string | null;
  lat?: number | null;
  long?: number | null;
  country?: string | null;
  state?: string | null;
  ipAddress?: string | null;
  city?: string | null;
}

let clientID = 'undefined';
let visitorInfo: VisitorData = {
  clientID: 'undefined',
  lat: null,
  long: null,
  country: 'undefined',
  state: 'undefined',
  ipAddress: 'undefined',
  city: 'undefined',
};

export const recordVisitorInfo = async () => {
  ReactGA.ga('create', 'UA-40645357-1', 'auto');
  ReactGA.ga(async (tracker) => {
    clientID = tracker.get('clientId');
  });
  const location: LocationLookupRes = await axios.get('https://geolocation-db.com/json/');
  visitorInfo = {
    clientID: clientID,
    lat: location.data.latitude,
    long: location.data.longitude,
    country: location.data.country_code,
    state: location.data.state,
    city: location.data.city,
    ipAddress: location.data.IPv4,
  };
  localStorage.setItem('vid', JSON.stringify(visitorInfo));
  return visitorInfo;
};

export const gaViewEvent = () => {
  const visitData: VisitorData = JSON.parse(localStorage.getItem('vid'));
  if (visitData) {
    if (!visitData.clientID) {
      clientID = 'undefined';
    } else {
      clientID = visitData.clientID;
    }
    const ipAddress = visitData.ipAddress;
    const url = window.location.href;
    const category = `url::${url}`;
    const action = `${format(
      new Date(),
      'MM/dd/yyyy mm:kk:ss',
    )}::${clientID}::url::${url}::::${ipAddress}`;
    const label = `${clientID}::url::${url}::::${ipAddress}`;
    ReactGA.event({ category: category, action: action, label: label });
  } else {
    return;
  }
};

export const gaButtonEvent = (buttonName: string, buttonId: string) => {
  const visitData: VisitorData = JSON.parse(localStorage.getItem('vid'));
  const clientID = visitData.clientID;
  const ipAddress = visitData.ipAddress;
  const identifier = removeSpaces(buttonId);
  const name = removeSpaces(buttonName);

  const category = `button::${identifier}::${name}`;
  const action = `${format(
    new Date(),
    'MM/dd/yyyy mm:kk:ss',
  )}::${clientID}::button::${identifier}::${name}::${ipAddress}`;
  const label = `${clientID}::${format(
    new Date(),
    'MM/dd/yyyy mm:kk:ss',
  )}::button::${identifier}::${name}::${ipAddress}`;
  ReactGA.event({ category: category, action: action, label: label });
};

export const gaFormEvent = (formId: string) => {
  const visitData: VisitorData = JSON.parse(localStorage.getItem('vid'));
  const clientID = visitData.clientID;
  const ipAddress = visitData.ipAddress;
  const url = window.location.href;
  const identifier = removeSpaces(formId);

  const category = `form::${identifier}::${url}`;
  const action = `${format(
    new Date(),
    'MM/dd/yyyy mm:kk:ss',
  )}::${clientID}::form::${identifier}::${url}::${ipAddress}`;
  const label = `${clientID}::${format(
    new Date(),
    'MM/dd/yyyy mm:kk:ss',
  )}::form::${identifier}::${url}::${ipAddress}`;
  ReactGA.event({ category: category, action: action, label: label });
};

export const getVisitorInfo = () => visitorInfo;
